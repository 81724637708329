// @codekit-prepend "jquery-3.6.4.js";

$(window).on('load resize', function(){
    if ($(window).width() <= 899){
        $('body').removeClass('desktop').addClass('mobile');
        
    } else {
        $('body').removeClass('mobile').addClass('desktop');
    }
});

$(document).ready(function () {
    $('#site').append('<div class="overlay hidden"></div>');
    $('#site').append('<div class="tothetop hidden"></div>');
    $('table#table').wrap('<div class="table-wrapper"></div>');
    $("nav#nav .toggle").on('touch click', function(){
        $("nav#nav .navigation,.overlay").toggleClass('hidden');
        $("nav#nav .toggle").toggleClass('active');
    });
    $(".overlay").on('touch click', function(){
        $("nav#nav .navigation,.overlay").addClass('hidden');
        $("nav#nav .toggle").removeClass('active');
    });
    $('nav#nav ul.menu').find('li:has(ul)').children('a').removeAttr('href').addClass("submenu");
    $('nav#nav ul.menu li a.submenu').on('touch click', function(){
        $(this).toggleClass("active").closest('li').find('ul').toggleClass('active');
    });
    $("nav#nav .navigation ul.menu").prepend('<div class="nav-header">Navigation</div>');
    $('nav#nav .navigation ul.menu li a.submenu').append('<span></span>');
    $("#site").scroll(function(){
        if ($(this).scrollTop() > 120) {
            $('nav#nav,.topbar').addClass('sticky');
            $('.tothetop').removeClass('hidden');
        } else {
            $('nav#nav,.topbar').removeClass('sticky');
            $('.tothetop').addClass('hidden');
        }
    });
    $(".tothetop").on('touch click', function(){
        $('#site').animate({scrollTop : 0},100);
	    return false;
    });
 });